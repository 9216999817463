<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isUpdateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-update-sidebar-active', val)"
    ><template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New Skill</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Skill Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                v-model="skill.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Skill Arabic Name -->
          <validation-provider
            #default="validationContext"
            name="arabic name"
            rules="required"
          >
            <b-form-group label="Arabic Name" label-for="name">
              <b-form-input
                id="name"
                v-model="skill.arabicName"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Skill Slug -->
          <validation-provider
            #default="validationContext"
            name="slug"
            rules="required"
          >
            <b-form-group label="Slug" label-for="name">
              <b-form-input
                id="slug"
                v-model="skill.slug"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Skill Type -->
          <validation-provider
            #default="validationContext"
            name="Is Active"
            rules="required"
          >
            <b-form-group
              label="Is Active"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="skill.isActive"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="skillStatuses"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="type"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">
              Update Skill
            </b-button>
            <b-button type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template></b-sidebar
  >
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch, computed } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import vSelect from "vue-select";
import useSkillsList from "./useSkillsList";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSpinbutton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isUpdateSidebarActive: {
      required: true,
      type: Boolean,
    },
    skill: {
      required: true,
      type: Object,
    },
  },
  setup(props, { emit }) {
    const { skillStatuses, updateSkill } = useSkillsList();

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    const onSubmit = async () => {
      const response = await updateSkill(props.skill);
      if (typeof response !== "undefined") {
        emit("refetch-data");
        emit("update:is-update-sidebar-active", false);
      }
    };

    return {
      refFormObserver,
      getValidationState,
      skillStatuses,
      resetForm,
      onSubmit,
    };
  },
};
</script>
